import "./styles/quasar.scss";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/roboto-font/roboto-font.css";
import Loading from 'quasar/src/plugins/Loading.js';import Notify from 'quasar/src/plugins/Notify.js';;

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: { Loading, Notify },
};
