import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ApiClient } from "@/generated/client";



export default /*@__PURE__*/_defineComponent({
  __name: 'AuthLayout',
  props: {
    apiClient: {}
  },
  setup(__props: any) {

const props = __props;


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_page = _resolveComponent("q-page")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, { view: "hHh lpR fFf" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_page_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_page, null, {
            default: _withCtx(() => [
              _createVNode(_component_router_view, {
                "api-client": props.apiClient
              }, null, 8, ["api-client"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})