import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "loading"
}

import { ref } from "vue";
import { apiClientFactory } from "./api/clientFactory";
import { useUserStore } from "./store/user";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const userStore = useUserStore();
const apiClient = apiClientFactory(userStore);

const isLoaded = ref(false);

async function refreshMaybeStaleTokens() {
  try {
    const newTokens = await apiClient.default.refreshTokensUserRefreshPost({
      refresh: userStore.refreshToken,
    });
    userStore.accessToken = newTokens.access;
    userStore.refreshToken = newTokens.refresh;
  } catch (error: unknown) {
    userStore.signOut();

    throw error;
  }

  isLoaded.value = true;
}

if (userStore.isSignedIn) {
  refreshMaybeStaleTokens()
} else {
  isLoaded.value = true;
}

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_spinner = _resolveComponent("q-spinner")!

  return (isLoaded.value)
    ? (_openBlock(), _createBlock(_component_router_view, {
        key: 0,
        "api-client": _unref(apiClient)
      }, null, 8, ["api-client"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_q_spinner, {
          size: "3rem",
          thickness: 2
        })
      ]))
}
}

})