import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

import { View } from "@/router/typedefs";
import { useUserStore } from "@/store/user";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ApiClient } from "@/generated/client";


interface MenuItem {
  label: string;
  icon: string;
  to: View;
  separator?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MainLayout',
  props: {
    apiClient: {}
  },
  setup(__props: any) {

const props = __props;

const route = useRoute();
const userStore = useUserStore();
const router = useRouter();
const $q = useQuasar();

const leftDrawerOpen = ref(false);

const toggleLeftDrawer = () => {
  leftDrawerOpen.value = !leftDrawerOpen.value;
};

const menuItems: MenuItem[] = [
  {
    label: "Dodaj nowe zamówienie",
    icon: "add",
    to: View.ORDER_ADD_NEW,
  },
  {
    label: "Lista zamówień",
    icon: "list",
    to: View.ORDER_LIST,
  },
];

const signOut = () => {
  userStore.signOut();
  router.push({ name: View.LOGIN });
};

const miniState = ref(true);

if (!userStore.isSignedIn) {
  router.push({ name: View.LOGIN });
}

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_header = _resolveComponent("q-header")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_drawer = _resolveComponent("q-drawer")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_page = _resolveComponent("q-page")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, { view: "hHh lpR fFf" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_header, {
        elevated: "",
        class: "bg-primary text-white"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                dense: "",
                flat: "",
                round: "",
                icon: "menu",
                onClick: toggleLeftDrawer
              }),
              (_unref($q).screen.gt.sm)
                ? (_openBlock(), _createBlock(_component_q_toolbar_title, { key: 0 }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode(" ŁAD CMS v0.2.0")
                    ])),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_q_toolbar_title, { key: 1 }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode("ŁAD")
                    ])),
                    _: 1
                  })),
              (_unref(userStore).isSignedIn)
                ? (_openBlock(), _createBlock(_component_q_btn, {
                    key: 2,
                    onClick: signOut,
                    flat: ""
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode(" Wyloguj się ")
                    ])),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_router_link, {
                    key: 3,
                    to: { name: _unref(View).LOGIN }
                  }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode("Zaloguj się ")
                    ])),
                    _: 1
                  }, 8, ["to"]))
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_q_drawer, {
        padding: "",
        class: "drawer rounded-borders text-primary",
        "show-if-above": "",
        modelValue: leftDrawerOpen.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((leftDrawerOpen).value = $event)),
        side: "left",
        bordered: "",
        mini: miniState.value,
        onMouseover: _cache[1] || (_cache[1] = ($event: any) => (miniState.value = false)),
        onMouseout: _cache[2] || (_cache[2] = ($event: any) => (miniState.value = true)),
        "mini-to-overlay": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_list, null, {
            default: _withCtx(() => [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(menuItems, (menuItem, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                  _createVNode(_component_q_item, {
                    clickable: "",
                    active: _unref(route).name === menuItem.to,
                    to: { name: menuItem.to },
                    class: "drawer-item",
                    "active-class": "drawer-item--active"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, { avatar: "" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_icon, {
                            name: menuItem.icon
                          }, null, 8, ["name"])
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(menuItem.label), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["active", "to"]),
                  (menuItem.separator)
                    ? (_openBlock(), _createBlock(_component_q_separator, {
                        key: 'sep' + index
                      }))
                    : _createCommentVNode("", true)
                ], 64))
              }), 64))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue", "mini"]),
      _createVNode(_component_q_page_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_page, null, {
            default: _withCtx(() => [
              _createVNode(_component_router_view, {
                "api-client": props.apiClient
              }, null, 8, ["api-client"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})